<script lang="ts">
	import { onMount } from 'svelte';
	
	import Login from 'src/components/auth/Login.svelte';
	import { accessToken, authStore } from 'src/stores/auth';
	import { Routes } from 'src/constants/router';
	import { goto } from '$app/navigation';
	onMount(async () => {
		
		if ($accessToken) {
			goto(Routes.ADMIN);
		}
	});
</script>

<div>
	<Login />
</div>
